import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero'
import About from './components/About';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Projects from './components/Projects';
import './i18n';


import ParticleBackground from './ParticleBackground';


function App() {
  return (
    <div className='overflow-hidden' >
      {/* <ParticleBackground/> */}
      <div className='myColor'>
      <div >
        <Navbar ></Navbar>
      </div>
        {/* <div className='positionnement'> */}
        <div>
          <Hero></Hero>
        </div>
        {/* </div> */}
        <About></About>  
        <Experience></Experience>   
        <Projects></Projects>
        <Contact></Contact>
      </div>
        <div>
          <Footer></Footer>
        </div>
    </div>
  );
}

export default App;
