import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import './Footer.css';



function Footer(){
    const{t,i18n} = useTranslation();
    return (
        <div className="d-xl-flex d-lg-flex d-md-grid justify-content-around align-items-center bg-dark text-light fw-bold pt-2 text-center">
            <p><FontAwesomeIcon icon={faPhone} className='me-1'></FontAwesomeIcon>514-928-7076</p>
            <hr></hr>
            <p><FontAwesomeIcon icon={faEnvelope} className='me-1'></FontAwesomeIcon>abdou.diarra@outlook.com</p>
            <hr></hr>
            <p className="mb-0 pb-2"><FontAwesomeIcon icon={faCode} className='me-1 '></FontAwesomeIcon>{t('madeInReact')}</p>
        </div>         
    );
}

export default Footer;