import React from 'react';
import './Projects.css';
import Project1 from '../images/project1.png';
import Project2 from '../images/project2.png';
import Project3 from '../images/project3.png';
import {useTranslation} from "react-i18next";
import i18n from '../i18n';

const changeLanguage = (ln) =>{
    return() => {
        i18n.changeLanguage(ln)
    }
}

function Projects() {

    const{t,i18n} = useTranslation();

  return (
    <div className="projects component-space mb-5 pb-5" id="projects">
    <div>
        <div className="heading text-light text-center border border-5 container mb-5"  data-aos="zoom-in-down">
            <h1>{t('Projects')}</h1>
        </div>
    </div>

        <div className="container">
           <div className="row">
             

             <div className="col-xl-4 col-12 text-center text-light" data-aos="zoom-out">
                    <div className="project__box__img pointer relative project__box"  >
                        <a href="https://abdouforest.netlify.app/" target="_blank">
                            <div className="project__img__box">
                                <img src={Project1} alt="" className="project__img" />
                                <p className="fw-bold text-secondary display-6 top-right-forest text-light mt-xl-5 ">Forest</p>
                                <p className="mt-xl-2 fw-bold bottom-left centered text-light mt-xl-5 lesssize">{t('MadeWith')} : HTML CSS BOOTSTRAP</p>
                            </div>
                        </a>
                        <div className="mask__effect"></div>
                    </div> 
             </div>

             
             <div className="col-xl-4 col-12 text-center text-light my-xl-0 my-5" data-aos="zoom-out">
                    <div className="project__box__img pointer relative project__box"  >
                        <a href="https://abdoumanage.netlify.app" target="_blank">
                            <div className="project__img__box">
                                <img src={Project2} alt="" className="project__img" />
                                <p className="fw-bold text-secondary display-6 top-right-manage text-center ms-0 text-light mt-xl-5 ">Manage</p>
                                <p className="mt-xl-2 fw-bold bottom-left centered text-light mt-xl-5 lesssize">{t('MadeWith')} : HTML CSS BOOTSTRAP JavaScript</p>
                            </div>
                        </a>
                        <div className="mask__effect"></div>
                    </div> 
             </div>

             <div className="col-xl-4 col-12 text-center text-light my-xl-0 my-5" data-aos="zoom-out">
                    <div className="project__box__img pointer relative project__box"  >
                        <a href="https://abdouloopstudio.netlify.app" target="_blank">
                            <div className="project__img__box">
                                <img src={Project3} alt="" className="project__img" />
                                <p className="fw-bold text-secondary display-6 top-right-loopstudio ms-0 text-light mt-xl-5">Loopstudios</p>
                                <p className="mt-xl-2 fw-bold bottom-left centered text-light mt-xl-5 lesssize">{t('MadeWith')} : HTML CSS BOOTSTRAP JavaScript</p>
                            </div>
                        </a>
                        <div className="mask__effect"></div>
                    </div> 
             </div>


             
             <div className="col-xl-4 col-12 text-center d-flex justify-content-center fw-bold display-4 align-items-center mt-5">
                <p className="card p-2" data-aos="zoom-out">{t('MoreToCome')}</p>
             </div>


             <div className='d-xl-grid d-lg-grid d-none'>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
             </div>

           </div>
       </div>
    </div>
  );
}

export default Projects