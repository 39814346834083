import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationFrench from "./locales/fr/translationFR.json";
import translationEnglish from "./locales/en/translationEN.json";

//translations
const resources = {
    en:{
        translation: translationEnglish
    },
    fr:{
        translation : translationFrench
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr',

        keySeparator: false,
        interpolation:{
            escapeValue: false
        }
    });

export default i18n;