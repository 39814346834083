import './Contact.css';
import Aos from 'aos';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Socials from './Socials';



function Contact(){
    const{t,i18n} = useTranslation();
    useEffect(() =>{
        Aos.init();
    });
    return(     
        
        <div>
            <div className='' id='contact' >
                <div className='container' data-aos="zoom-out">
                    <h1 className='border border-5 border-light text-light text-center rounded'>Contact</h1>
                </div>

                <div className="  get_in_touch card " data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000">
                    <h1 className='text-center title mb-4 text-decoration-underline'>{t('getInTouch')}</h1>

                            <form action='https://formsubmit.co/abdou.diarra@outlook.com' method='post'>
                    <div className="container ">
                        <div className="contact-form row">
                            <div className="form-field col-lg-6">
                                <input id="name" className="input-text" type="text" name="name" required></input>
                                <label htmlFor="name" className="label">{t('name')}</label>
                            </div>
                            <div className="form-field col-lg-6">
                                <input id="email" className="input-text" type="email" name='email' required></input>
                                <label htmlFor="email" className="label">{t('email')}</label>
                            </div>
                            <div className="form-field col-lg-6">
                                <input id="company" className="input-text" type="text" name='company' required></input>
                                <label htmlFor="company" className="label">{t('company')}</label>
                            </div>
                            <div className="form-field col-lg-6">
                                <input id="phone" className="input-text" type="tel" name='number'></input>
                                <label htmlFor="phone" className="label">{t('phone')}</label>
                            </div>
                            
                            <div className="form-field col-lg-6">
                                <input id="message" className="input-text" type="message" name='message'></input>
                                <label htmlFor="message" className="label">{t('message')}</label>
                            </div>

                            <div className="form-field col-lg-6">
                                <button className="submit-btn" type="submit" name="">{t('submit')}</button>
                            </div>

                        </div>
                    </div>
                    </form>
                    <div className=''>
                        <Socials></Socials>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    );

}

export default Contact;