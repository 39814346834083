
import css from '../images/css3.png'
import html from '../images/html.png'
import javascript from '../images/javascript.png'
import react from '../images/react.png'
import java from '../images/java.png'
import bootstrap from '../images/bootstrap.png'
import sql from '../images/sql.png'
import angular from '../images/angular.png'
import './Technologies.css';
import Aos from 'aos';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";



function Technologies(){
    const{t,i18n} = useTranslation();
    useEffect(() =>{
        Aos.init();
    });
    return(
        <div className="mb-4 pb-5 vh-50">
            <div className=''>
                <h1 data-aos="zoom-in-down" className=' border border-5 border-light rounded text-center'>{t('technologies')}</h1>
            </div>
            <div className=" text-light d-xl-flex d-lg-flex d-md-grid d-sm-grid justify-content-around mt-5 text-center" > 
                
                <img className='nixa animate m-2' src={html} data-aos="zoom-out-up"></img>
                <img className='nixa animate m-2' src={css} data-aos="zoom-out-up"></img>
                <img className='nixa animate m-2' src={javascript} data-aos="zoom-out-up"></img>
                <img className='nixa animate m-2' src={react} data-aos="zoom-out-up"></img>
                <img className='nixa animate m-2' src={java} data-aos="zoom-out-up"></img>
                <img className='nixa animate m-2' src={bootstrap} data-aos="zoom-out-up"></img>
                <img className='nixa animate m-2' src={sql} data-aos="zoom-out-up"></img>
                <img className='nixa animate m-2' src={angular} data-aos="zoom-out-up"></img>
            </div> 
            <div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    );
}

export default Technologies;