import coding from '../images/coding.jpg';
import nixa from '../images/nixa.png';
import './Experience.css'
import Technologies from './Techonologies';
import {useTranslation} from "react-i18next";

// import ParticleBackground from '../ParticleBackground';


function Experience(){
    const{t,i18n} = useTranslation();

    return (
        <div className="text-white " >
            <div className='container' id='experience'>
                
                        <h1 className=' text-center border border-light border-5 rounded mb-5' data-aos="zoom-in-down">{t('experienceTitle')}</h1>
                <div className='row '>


                    <div className='col-xl-6 col-md-12 col-sm-12 my-auto text-center mt-sm-5 ' data-aos="fade-down" id="about">
                        <span><img className='img-thumbnail p-2 experienceLogo animate ' src={coding} alt='coding'></img></span>
                    </div>
                    
                    <div className='col-xl-5 col-md-12 col-sm-12  my-5 ms-xl-5 ' data-aos="fade-up">
                        <p className='card text-dark p-xl-4 p-sm-3 p-md-3 p-3 me-md-5 ms-md-5'>
                            {t('experienceDesc')}                        
                        </p>
                        <a href="https://nixa.ca/fr/" target='_blank' rel='noreferrer' className='text-decoration-none'>
                            <div className='card  me-md-5 ms-md-5'>
                                <div className='row text-dark '>
                                    <div className='col-4 mx-0'>
                                        <img className='card nixa' src={nixa} alt='nixa logo'></img>
                                    </div>
                                    <div className='col-6 fw-bold p-0 m-0'>
                                        <p className=' my-auto mt-4 '>{t('nixa')}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='mt-5'>
                        <Technologies></Technologies>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience;