import './About.css';
import whoami from '../images/whoami.jpg';
import 'aos/dist/aos.css';
import {useEffect} from 'react';
import Aos from 'aos';
import Socials from './Socials';
import cv from '../download/cvabdou.docx';
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import i18n from '../i18n';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

function About(){
    const{t,i18n} = useTranslation();
    useEffect(() =>{
        Aos.init();
    });

    // const [locale, setLocale] = useState('en');
    return(
        <div id='about' className='pb-5 mb-5'>
            <div className='container  mt-sm-0 pt-3 ps-xl-5 vh-100 pb-5 mb-5' id='about'>
                    <h1 className=' text-center border border-5 border-light text-light rounded' data-aos="zoom-out-down" >{t('whoami')}</h1>
                <div className='row pt-5 '>

                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12  order-2  ' data-aos="zoom-in" >
                        <p className='card text-dark me-md-5 p-3 pb-xl-4 pb-lg-3 '>
                            {t('whoamiOne')}
                            <br></br>
                            <br></br>
                            {t('whoamiTwo')}
                        </p>
                        <div className='ms-lg-5'>
                            <Button href='#contact' variant="contained" className="ms-lg-5">{t('contactMe')}</Button>
                            <Button href={cv} variant="outlined" className="mx-lg-2 mx-2" id='resume'>{t('download')}</Button>
                        </div>
                    </div>
                
                    <div className='col-xl-6 col-md-12 col-sm-12  text-center  order-1' data-aos="zoom-in" >
                        <img className='animate img-thumbnail meLogo aboutImg' src={whoami} alt='my logo'></img>
                    </div>

                </div>
            </div>
            <div className='d-xl-none d-lg-none d-grid '>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
             </div>
        </div>
    );
}

export default About;