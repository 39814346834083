
import LinkedIn from '../images/linked.png'
import Github from '../images/github.png'
import Aos from 'aos';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";



function Socials(){
    const{t,i18n} = useTranslation();
    useEffect(() =>{
        Aos.init();
    });
    return(
        <div className="">
            {/* <div className=''>
                <h1 data-aos="zoom-in-down" className=' text-center text-light px-3'>{t('socials')}</h1>
            </div> */}
            <div className='row ms-xl-5 ps-xl-5 '>
                <div className=" text-light col-6 col-lg-3  mt-5  " > 
                        <a href='https://github.com/abdoudiarra' target="_blank" rel='noreferrer'><img className='nixa animate card ' src={Github} data-aos="zoom-out-up" alt='github'></img></a>
                </div>
                <div className='text-light col-6 col-lg-3 justify-content-around mt-5'>
                    <a href='https://www.linkedin.com/in/abdou-diarra' target="_blank" rel='noreferrer'><img className='nixa animate ' src={LinkedIn} data-aos="zoom-out-up" alt='linkedIn'></img></a>
                </div>

            </div>
        </div>
    );
}

export default Socials;