import './Navbar.css';
import logo from '../images/logo.jpeg';
import {useTranslation} from "react-i18next";
import i18n from '../i18n';


const changeLanguage = (ln) =>{
    return() => {
        i18n.changeLanguage(ln)
    }
}

function Navbar(){
    const{t,i18n} = useTranslation();

    return (
        
        <div className="navbar navbar-expand-lg myColor text-light  fixed-top ">

            <div className="container mt-3">
                <div className="row ">
                    <div className="col-xl-4 col-lg-3 col-md-3  ms-xl-0 ms-5">
                        {/* <p className="d-inline-block ">Logo</p>  */}
                        <a href='https://abdoudiarra.com'><img className='card p-2 ms-xl-0 ms-5 me-xl-0' src={logo}></img></a>
                    </div>
                   
                    {/* <div className=" wv-100 collapse navbar-collapse d-xl-inline d-lg-inline-block d-sm-none col-md-2 " id="navbarScroll"> */}
                        <ul className="col-xl-4 col-lg-12  col-12 navbar-nav navbar-nav-scroll d-flex menu">
                            <div className='row'>

                                <div className='wv-100 collapse navbar-collapse d-xl-inline-block d-lg-inline-block d-sm-inline-block d-inline-block  col-md-2'>
                                    <a href="#about" className=" text-decoration-none text-light fw-bold menu d-xl-inline d-none">{t('about')}</a>
                                    <a href="#experience" className=" text-decoration-none text-light fw-bold menu d-xl-inline d-none">{t('experience')}</a>
                                    <a href="#projects" className=" text-decoration-none text-light fw-bold menu d-xl-inline d-none">{t('projects')}</a>
                                    <a href="#contact" className=" text-decoration-none text-light fw-bold menu d-xl-inline d-none">{t('contact')}</a>
                                    <a href="#about" className=" text-decoration-none text-light fw-bold menu d-xl-inline d-none">{t('resume')}</a>
                                    <div className='mx-lg-0 mx-4'>
                                        <div className='mx-lg-0 mx-4'>
                                            <div className=' d-flex justify-content-start align-items-end ms-md-2 ms-lg-5 mx-xl-0 mx-5 px-xl-0 px-5 mt-xl-0 mt-3'>
                                                <div className='col-xl-12 col-lg-12 col-md-4 d-xl-flex bg-lang rounded p-xl-2 ms-lg-4 pe-1  pb-1 '>
                                                    <p className='text-center fw-bold p-xl-0 m-xl-0 mx-xl-3 '>{t('language')}</p>
                                                    <button onClick={changeLanguage("fr")} className=' persoColorFrench rounded px-3 mx-1 fw-bold'>Fr</button>
                                                    <button onClick={changeLanguage("en")} className='persoColorEnglish rounded px-3 fw-bold'>En</button>
                                                </div>                
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </ul>
                    {/* </div>     */}

                </div>
            </div>
        </div>
    );
}

export default Navbar;