import './Hero.css';
import ParticleBackground from '../ParticleBackground';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {faLaptopCode} from "@fortawesome/free-solid-svg-icons";
import {faUserGraduate} from "@fortawesome/free-solid-svg-icons";
import Particles from 'react-tsparticles';
import {useTranslation} from "react-i18next";


function Hero(){
    const{t,i18n} = useTranslation();
    return (
        <div>
            <ParticleBackground/>
            <div className='d-flex justify-content-center align-items-center vh-100 overflow-hidden'>
                <div className=' '>
                    <div className='text-center '>     
                        <h1 className="myName my-auto my-5 py-5 glitch text-light">Abdou Diarra</h1>
                        <p className='display-6 fw-bold text-light'><span>{t('introduction')}</span></p>
                    </div>
                        <div className='d-xl-flex d-md-none d-sm-none d-none align-items-end  justify-content-between myColor mt-5 pt-5'>
                            <span className='mx-3'><FontAwesomeIcon icon={faCode} className='text-light display-6 offset-4 mb-3'></FontAwesomeIcon><p className='text-light fw-bold glitch '>{t('myself1')}</p></span>
                            <span className='mx-3'><FontAwesomeIcon icon={faLaptopCode} className='text-light display-6 offset-3 mb-3'></FontAwesomeIcon><p className='text-light fw-bold glitch'>{t('myself2')}</p></span>
                            <span className='mx-3'><FontAwesomeIcon icon={faUserGraduate} className='text-light display-6  offset-5 mb-3'></FontAwesomeIcon><p className='text-light fw-bold glitch'>{t('myself3')}</p></span>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;